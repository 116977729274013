"use client";

import Link from "next/link";
import { ArrowRight } from "lucide-react";

import { Button } from "@/components/ui/button";
import useGTMEvent from "@/hooks/useGTM";

export const StopSection = () => {
  const sendGTMEvent = useGTMEvent();

  return (
    <section className="container py-8" id="stops">
      <div className="max-w-screen-xl mx-auto px-0 md:px-6 gap-12 text-gray-600">
        <div className="relative isolate max-w-5xl mx-auto bg-foreground dark:bg-card dark:border dark:border-border rounded-2xl p-4 py-8 sm:p-20">
          <div className="flex flex-col gap-8 mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-xl text-center">
              <p className="font-bold tracking-tight text-white text-3xl sm:text-5xl pb-4">
                10x votre business avec Celerinc.
              </p>
              <div className="flex justify-center w-full">
                <p className="mt-4 text-lg text-white">
                  Développez des solutions logicielles autour de vos services ou
                  de vos produits existants et apportez plus de satisfaction à
                  vos clients.
                </p>
              </div>
            </div>
            <div className="flex items-center mx-auto justify-center gap-x-6">
              <Link
                aria-label="Prendre un rendez-vous"
                href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
                target="_blank"
              >
                <Button
                  className="font-bold group/arrow"
                  onClick={() =>
                    sendGTMEvent("ci_event", {
                      category: "meeting",
                      value: "book_a_meeting",
                    })
                  }
                >
                  Prendre un rendez-vous
                  <ArrowRight className="size-5 ml-2 group-hover/arrow:translate-x-1 transition-transform" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
