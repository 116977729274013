import { StarIcon } from "../icons/star-icon";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";

export interface TestimonialProps {
  name: string;
  title: string;
  avatar: string;
  content: string;
  fallback?: string;
}

function FiveStars() {
  return (
    <div className="flex items-center text-amber-400">
      <StarIcon className="size-4" />
      <StarIcon className="size-4" />
      <StarIcon className="size-4" />
      <StarIcon className="size-4" />
      <StarIcon className="size-4" />
    </div>
  );
}

export function ReviewCard({
  name,
  title,
  avatar,
  content,
  fallback,
}: TestimonialProps) {
  return (
    <div className="w-80 space-y-3 rounded-lg bg-background px-5 py-4 text-sm shadow-sm border border-secondary dark:border-border shadow-inner dark:bg-card">
      <div className="flex items-center gap-2">
        <Avatar>
          <AvatarImage alt={`${name}'s photo`} src={avatar} />
          <AvatarFallback>{fallback}</AvatarFallback>
        </Avatar>
        <div>
          <div className="font-medium">{name}</div>
          <div className="text-xs text-stone-600 dark:text-white/70">
            {title}
          </div>
        </div>
      </div>
      <FiveStars />
      <div className="whitespace-pre-line text-stone-600 dark:text-white">
        {content}
      </div>
    </div>
  );
}
