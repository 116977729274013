"use client";

import { icons } from "lucide-react";
import Link from "next/link";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Button } from "@/components/ui/button";
import useGTMEvent from "@/hooks/useGTM";

interface PromisesProps {
  icon: string;
  title: string;
  description: string;
}

const promiseList: PromisesProps[] = [
  {
    icon: "Blocks",
    title: "Produit / UX",
    description:
      "Nous développons des logiciels sur mesure en garantissant des produits évolutifs dès la conception.",
  },
  {
    icon: "LineChart",
    title: "Dev Sprint",
    description:
      "Exploitez notre méthode agile pour développer votre produit et s'adapter rapidement aux changements.",
  },
  {
    icon: "Wallet",
    title: "Aucun Surcoût",
    description:
      "Maîtrisez les coûts de vos produits avec notre stratégie de Sprint transparente et efficace.",
  },
  {
    icon: "Sparkle",
    title: "Analyse Produit",
    description:
      "Utilisez nos outils d'analyse pour améliorer votre produit et l'expérience utilisateur.",
  },
];

export const PromisesSection = () => {
  const sendGTMEvent = useGTMEvent();

  return (
    <section id="benefits" className="container py-24 sm:py-32">
      <div className="grid lg:grid-cols-2 place-items-center gap-6 lg:gap-24">
        <div className="text-center lg:text-left">
          <h2 className="text-lg text-primary mb-2 tracking-wider">Promesse</h2>

          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Projets aboutis à 200%.
          </h2>
          <p className="text-lg text-muted-foreground mb-8">
            Nous livrons le meilleur produit que vous voulez, de la conception
            et développement du produit à la maîtrise des coûts et au contrôle
            des performances.
          </p>

          <div className="flex flex-col items-center md:items-start">
            <Link
              aria-label="Prendre un rendez-vous"
              href="https://calendar.app.google/uWahKEJtqJBQUMMJA"
              target="_blank"
            >
              <Button
                className="font-bold w-full"
                onClick={() =>
                  sendGTMEvent("ci_event", {
                    category: "meeting",
                    value: "book_a_meeting",
                  })
                }
              >
                Prendre un rendez-vous
              </Button>
            </Link>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 w-full">
          {promiseList.map(({ icon, title, description }, index) => (
            <Card
              key={title}
              className="bg-tertiary hover:bg-background transition-all delay-75 group/number"
            >
              <CardHeader>
                <div className="flex justify-between">
                  <Icon
                    name={icon as keyof typeof icons}
                    size={32}
                    color="hsl(var(--primary))"
                    className="mb-6 text-primary"
                  />
                  <span className="text-5xl text-muted-foreground/15 font-medium transition-all delay-75 group-hover/number:text-muted-foreground/30">
                    0{index + 1}
                  </span>
                </div>

                <CardTitle>{title}</CardTitle>
              </CardHeader>

              <CardContent className="text-muted-foreground text-sm">
                {description}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};
