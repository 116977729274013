"use client";

import { Marquee } from "@/components/ui/marquee";
import { ReviewCard } from "@/components/ui/review-card";

interface ReviewProps {
  comments: { fr: string; en: string };
  fallback?: string;
  image: string;
  name: string;
  rating: number;
  userName: string;
}

const testimonials: ReviewProps[] = [
  {
    name: "Antoine Onnen",
    comments: {
      fr: "Celerinc a été clé pour faire avancer notre projet SaaS. Nous continuerons à travailler ensemble avec confiance totale.",
      en: "Celerinc was key in advancing our SaaS project. We will continue to work together with complete trust.",
    },
    fallback: "AO",
    image: "/assets/reviews/client-ao.jpg",
    rating: 5.0,
    userName: "CEO @Societeinfo",
  },
  {
    name: "Alexandre Philippot",
    comments: {
      fr: "Notre projet a atteint des résultats concrets et différenciants avec Celerinc, grace à leur approche Sprint.",
      en: "Our project achieved tangible and differentiating results with Celerinc, thanks to their Sprint approach.",
    },
    fallback: "AP",
    image: "/assets/reviews/client-ap.jpg",
    rating: 4.8,
    userName: "CEO @Polycube, Agile Coach",
  },
  {
    name: "Mickael Martin",
    comments: {
      fr: "Celerinc est un véritable partenaire SaaS qui couvre la stratégie produit au développement, rapide et efficace!",
      en: "Celerinc is a true SaaS partner covering everything from product strategy to development, fast and efficient!",
    },
    fallback: "MM",
    image: "/assets/reviews/client-mm.png",
    rating: 5.0,
    userName: "CEO @Unidite, Sales / CRM",
  },
  {
    name: "Youssef Ghalem",
    comments: {
      fr: "Avec Celerinc, nous avons pu sortir un MVP en 10 jours de 02Book, et on continue ensemble!",
      en: "With Celerinc, we were able to launch an MVP of 02Book in 10 days, and we continue together!",
    },
    fallback: "YG",
    image: "/assets/reviews/client-yg.jpg",
    rating: 5.0,
    userName: "Entrepreneur, AI Leader @Infosysta",
  },
  {
    name: "Fang Zhao",
    comments: {
      fr: "J'ai pu développer un outil d'analyse de données financières en 5 semaines et recueillir des retours rapidement!",
      en: "I developed a financial data analysis tool with Celerinc in 5 weeks and it helped me collect feedback quickly!",
    },
    fallback: "FZ",
    image: "/assets/reviews/client-fz.jpg",
    rating: 4.9,
    userName: "Researcher in VC Industry",
  },
];

export const ReviewsSection = () => {
  const lang: "en" | "fr" = "fr";

  return (
    <section className="container py-24 sm:py-32" id="testimonials">
      <div className="text-center mb-8">
        <h2 className="text-lg text-primary text-center mb-2 tracking-wider">
          Témoignages
        </h2>

        <h2 className="text-3xl md:text-4xl text-center font-bold mb-4">
          Ce que pensent nos clients et partenaires
        </h2>
      </div>

      <Marquee className="py-2" fade={true}>
        {testimonials.map((testimonial, i) => (
          <ReviewCard
            key={i}
            name={testimonial.name}
            title={testimonial.userName}
            avatar={testimonial.image}
            fallback={testimonial.fallback}
            content={testimonial.comments[lang]}
          />
        ))}
      </Marquee>

      <Marquee className="py-2" reverse={true} fade={true}>
        {testimonials.map((testimonial, i) => (
          <ReviewCard
            key={i}
            name={testimonial.name}
            title={testimonial.userName}
            avatar={testimonial.image}
            fallback={testimonial.fallback}
            content={testimonial.comments[lang]}
          />
        ))}
      </Marquee>
    </section>
  );
};
